import React from "react";
import { Link } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons";
import "./index.css";

const Navbar = () => {
  return (
    <nav className="navbar-container">
      <Link
        activeClass="active"
        to="galeria"
        spy={true}
        smooth={true}
        offset={-50}
        duration={500}
      >
        <h1>GALERÍA</h1>
      </Link>
      <Link
        activeClass="active"
        to="libros"
        spy={true}
        smooth={true}
        offset={-50}
        duration={500}
      >
        <h1>LIBROS</h1>
      </Link>
      <Link
        activeClass="active"
        to="noticias"
        spy={true}
        smooth={true}
        offset={-50}
        duration={500}
      >
        <h1>NOTICIAS</h1>
      </Link>
      <a href="https://www.instagram.com/julianabasolorodriguez">
        <FontAwesomeIcon size="2x" icon={faInstagram} />
      </a>
      <a href="https://www.facebook.com/groups/913742686563329/user/100079362846585">
        <FontAwesomeIcon size="2x" icon={faFacebook} />
      </a>
    </nav>
  );
};

export default Navbar;
