import React, { useState } from "react";
import { Drawer, Space } from "antd";
import { Divider } from "antd";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import "./index.css";

const DrawerComponent = ({ data = [] }) => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const dynamicIcon = (str) => {
    switch (str) {
      case "facebook":
        return (
          <FontAwesomeIcon
            size="2x"
            icon={faFacebook}
            className="network-facebook-icon-author"
          />
        );
      case "email":
        return (
          <FontAwesomeIcon
            size="2x"
            icon={faEnvelope}
            className="network-icon-author"
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      <Space>
        <button className="button-title" onClick={showDrawer}>
          <h3>Valoraciones</h3>
        </button>
      </Space>
      <Drawer
        title={
          <section className="drawer-title-section">
            <h3 className="drawer-title">Valoraciones</h3>
            <button className="drawer-title-button" onClick={onClose}>
              <FontAwesomeIcon size="2x" icon={faXmark} />
            </button>
          </section>
        }
        placement="right"
        closable={false}
        onClose={onClose}
        open={open}
        key={"right"}
      >
        {data?.map(({ author, text, year, network, origin }, key) => {
          return (
            <span className="drawer-reviews-container" key={key}>
              <Divider orientation="left">
                <h3 className="review-year">{year}</h3>
              </Divider>
              <h2 className="review-text">{parse(text)}</h2>
              <span>
                <h2 className="review-author">{parse(author)}</h2>
                {network !== "email" && (
                  <span className="review-author-container">
                    {network && dynamicIcon(network)}
                    <a href={origin.net} className="net-link-text">
                      {origin.title}
                    </a>
                  </span>
                )}
              </span>
            </span>
          );
        })}
      </Drawer>
    </>
  );
};
export default DrawerComponent;
