const images = [
  {
    img: require("../images/carousel/algo_que_contar/charla_micro.jpg"),
    legend: "",
    style: {
      width: "25vw",
    },
  },
  {
    img: require("../images/carousel/algo_que_contar/presentacion_portada.jpg"),
    legend: "",
    style: {
      width: "45vw",
    },
  },
  {
    img: require("../images/carousel/algo_que_contar/firma_portada9.jpg"),
    legend: "",
    style: {
      width: "45vw",
    },
  },
  {
    img: require("../images/carousel/algo_que_contar/firma_portada.jpg"),
    legend: "",
    style: {
      width: "45vw",
    },
  },
  {
    img: require("../images/carousel/algo_que_contar/firma_portada1.jpg"),
    legend: "",
    style: {
      width: "45vw",
    },
  },
  {
    img: require("../images/carousel/algo_que_contar/firma_portada2.jpg"),
    legend: "",
    style: {
      width: "45vw",
    },
  },
  {
    img: require("../images/carousel/algo_que_contar/firma_portada3.jpg"),
    legend: "",
    style: {
      width: "45vw",
    },
  },
  {
    img: require("../images/carousel/algo_que_contar/firma_portada4.jpg"),
    legend: "",
    style: {
      width: "45vw",
    },
  },
  {
    img: require("../images/carousel/algo_que_contar/firma_portada5.jpg"),
    legend: "",
    style: {
      width: "45vw",
    },
  },
  {
    img: require("../images/carousel/algo_que_contar/firma_portada6.jpg"),
    legend: "",
    style: {
      width: "45vw",
    },
  },
  {
    img: require("../images/carousel/algo_que_contar/firma_portada7.jpg"),
    legend: "",
    style: {
      width: "45vw",
    },
  },
  {
    img: require("../images/carousel/algo_que_contar/firma_portada8.jpg"),
    legend: "",
    style: {
      width: "45vw",
    },
  },

  {
    img: require("../images/carousel/algo_que_contar/foto_mesa_escritorio.jpg"),
    legend: "",
    style: {
      width: "45vw",
    },
  },
];

export default images;
