const news = [
  {
    title: "ElEscritor.es con Julián Abasolo Rodríguez",
    description:
      "Entrevistamos al escritor Julián Abasolo Rodríguez, por la publicación de su obra “Algo que contar”",
    img: require("../images/carousel/algo_que_contar/foto_mesa_escritorio.jpg"),
    linkDetails: {
      link: "https://elescritor.es/entrevista/entrevistamos-al-escritor-julian-abasolo-rodriguez-por-la-publicacion-de-su-obra-algo-que-contar/",
      linkImg: require("../images/logos/el_escritor.png"),
    },
  },
];

export default news;
