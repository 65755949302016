import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import images from "../../assets/mocks/carrousel";
import "./index.css";

const Carrousel = () => {
  const [activeImage, setActiveImage] = useState(0);
  return (
    <section className="carrousel-section" id="galeria">
      <h1>GALERÍA DE FOTOS</h1>
      <div>
        <button
          className={activeImage > 0 ? "button-active" : "button-inactive"}
          onClick={() => activeImage > 0 && setActiveImage(activeImage - 1)}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <img src={images[activeImage].img} style={images[activeImage].style} alt="active-img-carousel" />
        <button
          className={
            activeImage < images.length - 1
              ? "button-active"
              : "button-inactive"
          }
          onClick={() =>
            activeImage < images.length - 1 && setActiveImage(activeImage + 1)
          }
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    </section>
  );
};

export default Carrousel;
