import React from "react";
import Carrousel from "../../components/carousel";
import News from "../../components/news";
import List from "../../components/list";
import fotoPerfil from "../../assets/images/people/foto_perfil.jpg";
import books from "../../assets/mocks/books";
import "./index.css";

const Homepage = () => {
  return (
    <section style={{paddingTop: "7.5vh"}}>
      <div className="biography-container">
        <img src={fotoPerfil} className="portada-img" alt="portada" />
        <span className="biography-info">
          <h1>JULIÁN ABASOLO RODRÍGUEZ</h1>
          <h2>
            Julián Abasolo Rodríguez nació en 1952 en Villafranca de los Barros
            (Badajoz) España. Por circunstancias que narra el propio autor en su
            obra, la familia emigró a Barcelona en 1954, en donde se crio, y,
            ahí, comenzó su odisea, su historia, la que narra con todo tipo de
            detalle y reflexión en su vibrante obra literaria de corte
            biográfico ALGO QUE CONTAR, su primer libro.
          </h2>
          <h2>
            En 1970, a la edad de dieciocho años, el autor decide cruzar la
            frontera y, después de un periplo de siete años de trabajar y
            residir en distintos países europeos, enriqueciéndose en ciencias de
            la vida, regresó a España, en donde comenzó a dedicarse y a ocupar
            diversos cargos de responsabilidad a lo largo y ancho de la
            geografía nacional dentro del Sector Servicios.
          </h2>
          <h2>
            Hijo de familia humilde que, debido a diversos acontecimientos que
            marcaron su infancia y que recoge en su extensa obra literaria,
            sobrevivió gracias al Auxilio Social y a la Beneficencia.
          </h2>
          <h2>
            Acabando ALGO QUE CONTAR y, descubriendo su verdadera vocación bien
            entrado ya en la madurez, el autor comenzó a escribir su primera
            novela: <em>Tres familias y un destino</em>, basada en hechos reales,
            llegando a la conclusión de hacer público una sincera confesión: <em>no
            sabría escribir una historia sin que haya un gran porcentaje de
            realidad.</em>
          </h2>
        </span>
      </div>
      <Carrousel />
      <List title="LIBROS" items={books} />
      <News />
    </section>
  );
};

export default Homepage;
