import React, { useState } from "react";
import ReactPlayer from "react-player";
import { isMobile } from "react-device-detect";
import Drawer from "../../drawer/index";

const Sinopsis = ({ data }) => {
  const [collapse, setCollapse] = useState(data.collapse);
  return (
    <>
      <div className="sinopsis-container">
        <div className="sinopsis-review-container">
          <img src={data.img} className="book-image" alt="book" />
          <Drawer data={data?.valoraciones} />
        </div>
        <span className="sinopsis-subcontainer">
          <b className="sinopsis-title">{data.title}</b>
          <b className="sinopsis-subtitle">{data.subtitle}</b>
          <h2>
            {collapse ? data.resumen : data.sinopsis}...
            <button
              className="button-collapse"
              onClick={() => setCollapse(!collapse)}
            >
              {collapse ? "Ver más" : "Ver menos"}
            </button>
          </h2>
          {!isMobile && (
            <div className="video-player">
              {data?.video && <ReactPlayer url={data.video} controls={true} />}
            </div>
          )}
        </span>
      </div>
    </>
  );
};

export default Sinopsis;
