import React from "react";
import news from "../../assets/mocks/news";
import "./index.css";

const News = () => {
  return (
    <section className="news-section" id="noticias">
      <h1 className="news-title">NOTICIAS</h1>
      <span>
        {news.map(
          ({ img, title, description, linkDetails: { link, linkImg } }, key) => {
            return (
              <div className="single-news-container" key={key}>
                <article className="single-article-container">
                  <img src={img} alt="imgArr" className="single-news-image" />
                  <span className="single-article-text-container">
                    <h1>{title}</h1>
                    <h2>{description}</h2>
                  </span>
                </article>
                <div className="border-radius-inverted-container">
                  <a href={link}>
                    <img
                      src={linkImg}
                      alt="linkArr"
                      className="single-news-link-image"
                    />
                  </a>
                </div>
              </div>
            );
          }
        )}
      </span>
    </section>
  );
};

export default News;
