import React from "react";
import Sinopsis from "./components/sinopsis.js";
import corteSvg from "../../assets/images/logos/el_corte_ingles.svg";
import amazonImg from "../../assets/images/logos/amazon.png";
import casaLibro from "../../assets/images/logos/casa_del_libro.png";
import librosCc from "../../assets/images/logos/libroscc.png";
import buscaLibre from "../../assets/images/logos/busca_libre.png";
import "./index.css";

const List = ({ title, items }) => {
  return (
    <div className="list-container" id="libros">
      <h1>{title}</h1>
      {items.map((data, key) => {
        return (
          <div className="book-container" key={key}>
            <Sinopsis data={data} />
            <div className="redirect-links-container">
              {data.plataformas.map((data_, key) => {
                return (
                  <span key={key}>
                    {data_.name === "corte" && !data_.pending && (
                      <a href={data_.url}>
                        <img src={corteSvg} className="svg-image" alt="svg" />
                      </a>
                    )}
                    {data_.name === "fnac" && !data_.pending && (
                      <a href={data_.url}>
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/2/2e/Fnac_Logo.svg"
                          className="fnac-image" alt="svg"
                        />
                      </a>
                    )}
                    {data_.name === "buscalibre" && !data_.pending && (
                      <a href={data_.url}>
                        <img src={buscaLibre} className="svg-image" alt="svg" />
                      </a>
                    )}
                    {data_.name === "libroscc" && !data_.pending && (
                      <a href={data_.url}>
                        <img src={librosCc} className="svg-image" alt="svg" />
                      </a>
                    )}
                    {data_.name === "amazon" && !data_.pending && (
                      <a href={data_.url}>
                        <img src={amazonImg} className="svg-image" alt="svg" />
                      </a>
                    )}
                    {data_.name === "casadellibro" && !data_.pending && (
                      <a href={data_.url}>
                        <img src={casaLibro} className="svg-image" alt="svg" />
                      </a>
                    )}
                  </span>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default List;
